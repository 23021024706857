<template lang="">
  <div>
    <!-- SECTION Card ket qua -->
    <BCard header-class="py-1">
      <template #header>
        <div class="d-flex-between w-100">
          <div class="font-weight-bolder text-uppercase">
            {{ $t('packageConfig.coupon.titleList') }}
          </div>

          <div>
            <BButton
              variant="primary"
              @click="createButtonHandle"
            >
              {{ $t('packageConfig.coupon.createCoupon') }}
            </BButton>
          </div>
        </div>
      </template>

      <BTable
        style="max-height: calc(100vh - 400px); height: calc(100vh - 400px)"
        :sticky-header="true"
        class="position-relative"
        :items="resultArray"
        responsive
        :striped="true"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        :busy="loading"
        :hover="true"
        :borderless="true"
      >
        <template #table-busy>
          <div class="d-flex-center text-dark my-2 gap-2">
            <b-spinner class="align-middle" />
            <strong>{{ $t('loading') }}</strong>
          </div>
        </template>

        <template
          v-for="column in tableColumns"
          v-slot:[`head(${column})`]=""
        >
          <span
            :key="column"
            class="text-dark text-nowrap"
          >
            {{ $t(`packageConfig.columns.${column}`) }}
          </span>
        </template>

        <template #cell(code)="row">
          <div
            class="hover-check d-flex flex-nowrap gap-2"
            style="width: 120px"
          >
            <kbd class="font-weight-bolder text-dark">
              {{ row.item.code }}
            </kbd>
            <BButton
              variant="flat-dark"
              class="p-50 rounded-circle hover-button-check"
              @click="copyCode(row.item.code)"
            >
              <feather-icon
                class="cursor-pointer"
                icon="CopyIcon"
              />
            </BButton>
          </div>
        </template>

        <template #cell(enable)="data">
          <IAmSwitch
            :checked.sync="data.item.enable"
            name="check-button"
            :confirm="true"
            custom-class="mr-0 mt-50 custom-control-success"
            :disabled="false"
            :re-update="false"
            @input="changeActive(data.item)"
          />
        </template>

        <template #cell(isUsed)="data">
          <div
            v-if="data.item?.isApplied"
          >
            <div class="d-flex flex-nowrap gap-2">
              <em>Mã đơn hàng:</em>
              <div class="font-weight-bolder">
                {{ data.item?.agencyPackageOrder?.orderCode }}
              </div>
            </div>
            <div class="d-flex flex-nowrap gap-2">
              <em>Mã đại lý:</em>
              <div class="font-weight-bolder">
                {{ data.item?.agencyPackageOrder?.buyer?.agencyCode }}
              </div>
            </div>
          </div>
          <em v-else>
            {{ 'Chưa sử dụng' }}
          </em>
        </template>

        <template #cell(percentage)="{item}">
          {{ item.percentage ? `${item.percentage}%` : '' }}
        </template>

        <template #cell(amountMax)="{item}">
          {{ formatCurrency(item.amountMax) }}
        </template>

        <template #cell(action)="{ item }">
          <div class="d-flex gap-2">
            <!-- <BButton
              variant="flat-warning"
              size="sm"
              @click="editHandle(item)"
            >
              Sửa
            </BButton> -->
            <BButton
              v-if="!item?.isApplied"
              variant="flat-danger"
              size="sm"
              @click="deleteHandle(item)"
            >
              Xoá
            </BButton>
          </div>
        </template>
      </BTable>

      <!-- ANCHOR: Table Footer -->
      <b-row class=" d-flex-center justify-content-md-between">
        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center mb-50 mb-md-0"
        >
          <span class="font-weight-bolder">
            {{ $t('paginationText.showing') }}
          </span>
          <v-select
            v-model="sizePerPage"
            :options="sizePerPageLgOptions"
            :clearable="false"
            :append-to-body="true"
            :calculate-position="withPopper"
            class="per-page-selector d-inline-block mx-50"
          >
            <template #option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #selected-option="data">
              <span>
                {{ data.label }}
              </span>
            </template>

            <template #no-options>
              {{ $t('noOptions') }}
            </template>
          </v-select>
          <span class="font-weight-bolder">
            {{ $t('paginationText.from') }} {{ fromItem }} {{ $t('paginationText.to') }} {{ toItem }} {{
              $t('paginationText.outOf') }} {{ totalItems }}
          </span>
        </b-col>

        <b-col
          cols="12"
          md="auto"
          class="d-flex align-items-center justify-content-center"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="sizePerPage"
            first-number
            last-number
            class="mb-0 mt-25 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </BCard>

    <PackageCouponModal @refetch-data="fetchPackageCoupons" />
    <!-- !SECTION -->
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BTable, BSpinner, BPagination,
} from 'bootstrap-vue'
import { createPopper } from '@popperjs/core'
import { useClipboard } from '@vueuse/core'

import { sizePerPageLgOptions } from '@/constants/selectOptions'
import usePackageCouponHandle from '@/views/retail-package/package-coupon/usePackageCouponHandle'

import { formatCurrency } from '@core/utils/filter'
import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BPagination,
    vSelect: () => import('vue-select'),
    IAmSwitch: () => import('@/components/IAmSwitch.vue'),
    PackageCouponModal: () => import('@/views/retail-package/package-coupon/PackageCouponModal.vue'),
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width || '200px'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
  setup() {
    const {
      resultArray,
      currentPage,
      sizePerPage,
      loading,
      totalItems,
      fromItem,
      toItem,
      fetchPackageCoupons,
      deletePackageCoupons,
      tableColumns,
      changeActive,
    } = usePackageCouponHandle()

    fetchPackageCoupons()

    function createButtonHandle() {
      this.$bvModal.show('modal-package-coupon')
    }

    function deleteHandle(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.confirmDelete'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            deletePackageCoupons(item.id)
          }
        })
    }
    const { copy } = useClipboard()
    function copyCode(code) {
      copy(code)
        .then(() => {
          this.$root.toastSuccess('reservation.sms.copySuccess')
        })
        .catch(() => {
          this.$root.toastError('reservation.sms.copyFail')
        })
    }

    return {
      loading,

      resultArray,
      tableColumns,

      removeAccentsUpperCaseFormatter,
      sizePerPageLgOptions,
      currentPage,
      sizePerPage,
      totalItems,
      fromItem,
      toItem,
      formatCurrency,
      changeActive,
      createButtonHandle,
      deleteHandle,
      fetchPackageCoupons,
      copyCode,
    }
  },
}
</script>

<style lang="scss" scoped>
.hover-check {
  &:hover {
    .hover-button-check{
      display: inline-block;
    }
  }
  &:not(:hover) {
    .hover-button-check{
      display: none;
    }
  }
}
</style>
